import axios from '@/libs/axios'

export default class TransferVouchers {
    
    getByFilters(query) {
        return axios.get(`/v2/ValesTraspasos/GetAllByFilters`, { params: query })
    }

    findByDoctoVeId(doctoVeId) {
        return axios.get(`/v2/ValesTraspasos/GetByDoctoVeId/${doctoVeId}`)
    }

    getAllByDoctoVeId(doctoVeId) {
        return axios.get(`/v2/ValesTraspasos/GetAllByDoctoVeId/${doctoVeId}`)
    }

    getAllByDoctoVeIdAndTipoMov(doctoVeId, tipoMov) {
        return axios.get(`/v2/ValesTraspasos/GetAllByDoctoVeIdAndTipoMov/${doctoVeId}/${tipoMov}`)
    }

    store(data) {
        return axios.post(`/v2/ValesTraspasos/Store`, data)
    }

    deactiveVoucher(doctoVeId) {
        return axios.delete(`/v2/ValesTraspasos/DeactiveDoctoByDoctoVeId/${doctoVeId}`)
    }
}