<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <div class="table-responsive">
            <b-table ref="refArticles" class="position-relative b-table-responsive mb-0 text-small small"
                :items="articles" responsive selectable select-mode="single" :fields="customColumns" primary-key="_key"
                show-empty empty-text="No se encontraron resultados" busy.sync="loading" small
                :sticky-header=" articles.length > 10 ?  isSticky : false"
                :no-border-collapse="true">

                <template #cell(unidades)="data">
                    <span v-if="isEdit">
                        <b-form-input 
                            :ref="'cantidadInput' + data.index"
                            size="sm"
                            v-model="data.item.unidades" 
                            :id="`article_id-${data.item.article_Id}`"
                            type="number" min="0" @keydown="handleKeyDown(data.index, $event)" />
                    </span>
                    <span v-else>
                        {{ data.item.unidades }}
                    </span>
                </template>
            </b-table>
        </div>
    </b-overlay>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
    BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
    BBadge, BCardSubTitle, BCardTitle, BTabs, BTab
} from 'bootstrap-vue'
import Swal from "sweetalert2";
import "animate.css";
import { v4 as uuidv4 } from 'uuid';
import router from "@/router";
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
    name: 'GlobalArticles',
    directives: {
        Ripple,
        'b-modal': VBModal,
        'b-toggle': VBToggle,
    },
    components: {
        BRow,
        BSidebar,
        BCol,
        BCard,
        BCardBody,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BOverlay,
        BSpinner,
        BTable,
        BTableLite,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BModal,
        VBModal,
        BForm,
        BIcon,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormTextarea,
        BFormTags,
        BCardCode,
        BAvatar,
        BPagination,
        BFormSelect,
        BBadge,
        BCardSubTitle,
        BCardTitle,
        BTabs,
        BTab,
    },
    mixins: [heightTransition],
    props: {
        isEdit: {
            type: Boolean,
            default: () => false,
        },
        articles: {
            type: Array,
            required: true,
            default: () => [],
        },
        loading: {
            type: Boolean,
            required: true,
            default: () => false,
        },
        isSticky: {
            type: Boolean,
            default: () => false,
        },
        isColumnDetails: {
            type: Boolean,
            default: () => false,
        },
        isEdit: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            columns: [
                {
                    key: 'articulo',
                    label: 'Articulo',
                    tdClass: 'text-left',
                    thClass: 'text-left',
                    tdStyle: 'font-size: 6px!important'
                },   
                {
                    key: 'unidad_Venta',
                    label: 'Um',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },               
                {
                    key: 'unidades',
                    label: 'Cantidad',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
            ],
            columnsDetails: [
                {
                    key: 'articulo',
                    label: 'Articulo',
                    thClass: 'text-left',
                    tdClass: 'text-left',
                },
                {
                    key: 'um',
                    label: 'Um',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    key: 'unidades_Pedidas',
                    label: 'Solicitan',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    key: 'enviadas',
                    label: 'Total Enviado',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    key: 'recibidas',
                    label: 'Total Recbido',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    key: 'unidades',
                    label: 'Cantidad',
                    thClass: this.isEdit ? '' : 'd-none',
                    tdClass: this.isEdit ? '' : 'd-none',
                },
            ],
        }
    },
    computed: {
        customColumns() {
            return this.isColumnDetails ? this.columnsDetails : this.columns;
        },
    },
    mounted() {
        
        //Posicionar el focus en el primer input
        this.$nextTick(() => {
            const nextInput = this.$refs['cantidadInput0'];
            if (nextInput) {
                nextInput.focus(); // Enfoca el siguiente input
                nextInput.select(); // Selecciona el texto del input
            }
        });
    },
    methods: {
        handleKeyDown(index, event) {
            if (event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'Enter') {
                event.preventDefault(); // Evita el comportamiento por defecto del navegador

                let nextIndex = index;
                if (event.key === 'ArrowDown' || event.key === 'Enter') {
                    nextIndex++;
                } else if (event.key === 'ArrowUp') {
                    nextIndex--;
                }

                // Asegúrate de que el índice esté dentro del rango válido
                if (nextIndex >= 0 && nextIndex < this.articles.length) {
                    // Obtén la referencia al siguiente input de la fila
                    this.$nextTick(() => {
                        const nextInput = this.$refs['cantidadInput' + nextIndex];
                        if (nextInput) {
                            nextInput.focus(); // Enfoca el siguiente input
                            nextInput.select(); // Selecciona el texto del input
                        }
                    });
                }
            }
        },
    },
    watch: {
        isColumnDetails(value) {
            this.$refs.refArticles.refresh();
        },
        isEdit(value) {
            //this.$refs.refArticles.refresh();

           
        },
    }

}
</script>

<style>
.b-table-sticky-header {
    overflow-y: auto;
    max-height: 350px;
}
</style>